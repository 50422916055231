<template>
    <div class="row">
        <q-banner-mensagem
            mensagem="Solicite a senha para realizar compras no site"
        />
        <div class="row justify-center q-mb-md q-mt-sm">
            <q-btn
                style="width: 100%; max-width: 320px;"
                color="primary"
                label="Solicitar Senha"
                @click="$_solicitarSenha"
            />
        </div>
    </div>
</template>

<script>

import QBannerMensagem from '@/components/shared/QBannerMensagem'

export default {
    name: 'QClienteSemCartao',

    components: {
        QBannerMensagem
    },

    methods: {
        $_solicitarSenha() {
            this.$router.push('/solicitacao-senha')
        }
    }
}
</script>

<style>

</style>
