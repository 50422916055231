import ServicesAuth from './servicesAuth'

class CartaoService extends ServicesAuth {
    constructor() {
        super({
            url: '/cartoes',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getCartao(id_cartao) {
        return this.axios.get(`${this.url}/${id_cartao}`, { params: { expand: 'cliente,dependente' } })
    }

    postReenvioCodigoAcesso(id_cartao) {
        return this.axios.post(`${this.url}/${id_cartao}/reenviar-codigo-acesso`)
    }

    putCriarSenha(id_cartao, codigoAacesso, senha) {
        return this.axios.put(`${this.url}/${id_cartao}/criar-senha`, { senha: senha, codigo_acesso: codigoAacesso })
    }
}

export default new CartaoService()
