import ServicesAuth from './servicesAuth'

class DependenteService extends ServicesAuth {
    constructor() {
        super({
            url: '/dependentes',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getLimite(id) {
        return this.axios.get(`${this.url}/${id}/limite-credito`)
    }

    getDependente(id) {
        return this.axios.get(`${this.url}/${id}`)
    }
}

export default new DependenteService()
