<template>
    <div>
      <div
        v-if="mostrarMensagem"
        :class="`text-center banner ${classe} ${uppercase ? 'text-uppercase' : ''} ${tipo == 'bg-warning' ? `bg-warning  ${cor_texto}` : `${ $q.dark.isActive ? 'bg-dark text-white' : `${tipo} ${cor_texto}` }` }`"
      >
        {{ mensagem }}
      </div>
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: "BannerMensagem",
  
    props: {
      mostrarMensagem: {
        type: Boolean,
        default: true,
      },
      mensagem: {
        type: String,
        required: true,
      },
      tipo: {
        type: String,
        default: "bg-warning",
      },
      cor_texto: {
        type: String,
        default: "text-black",
      },
      classe: {
        type: String,
        default: "",
      },
      uppercase: {
        type: Boolean,
        default: true,
      },
    },
  };
  </script>
  
  <style>
  .banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  </style>
  