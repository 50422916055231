<template>
    <q-page>
        <div
            class="row"
            style="max-width: 1200px; margin-left: auto; margin-right: auto;"
        >
            <div class="full-width">
                <div :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'} q-pa-sm`">
                    <div class="col-12 q-vertical-space">
                        <template v-if="loadingClientes">
                            <q-skeleton
                                width="100%"
                                height="50px"
                            />
                        </template>
                        <template v-else>
                            <q-select
                                filled
                                id="selCliente"
                                label="Selecione um cliente"
                                color="primary"
                                v-model="clienteSelecionado"
                                :options="clienteOptions"
                            />
                        </template>
                    </div>
                </div>
                <template v-if="mostrarClienteSemCartao">
                    <q-cliente-sem-cartao />
                </template>
                <template v-if="mostrarDependenteSemCartao">
                    <q-banner-mensagem
                        mensagem="Entre em contato com uma loja física para solicitar um cartão e/ou senha"
                    />
                </template>
                <template v-if="clienteSelecionado">
                    <template v-if="mostrarCartao">
                        <div :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'} q-mt-sm q-pa-md`">
                            <div class="col-12 col-md-6 q-pa-sm">
                                <q-cartao-digital
                                    :id-cartao="idCartao"
                                    :loading-cartao="loadingCartao"
                                    :empresa="empresa"
                                    :style-dados-cartao="styleDadosCartao"
                                    :nome-cliente="nomeCliente"
                                    :data-cadastro="dataCadastro"
                                    :numero-cartao-mascara="numeroCartaoMascara"
                                    :numero-cartao="numeroCartao"
                                    :mostrar-botao-copiar-numero="true"
                                />
                            </div>
                            <div class="col-12 col-md-6 q-pa-sm">
                                <template v-if="loadingLimite">
                                    <div class="col">
                                        <div class="row">
                                            <q-skeleton
                                                width="200px"
                                                height="25px"
                                            />
                                        </div>
                                        <div class="row q-mt-sm">
                                            <q-skeleton
                                                width="150px"
                                                height="20px"
                                            />
                                        </div>
                                        <div class="row q-mt-sm">
                                            <q-skeleton
                                                width="150px"
                                                height="20px"
                                            />
                                        </div>
                                        <div class="row q-mt-sm">
                                            <q-skeleton
                                                width="150px"
                                                height="20px"
                                            />
                                        </div>
                                        <div class="row q-mt-sm">
                                            <q-skeleton
                                                width="100%"
                                                height="40px"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col flex flex-center">
                                        <q-card class="full-width">
                                            <q-card-section>
                                                <div class="text-subtitle1 text-weight-large text-blue-grey-4">
                                                    LIMITE DE CRÉDITO
                                                </div>
                                            </q-card-section>
                                            <q-separator />
                                            <q-card-section>
                                                <div class="row q-mt-md">
                                                    <div class="col-4">
                                                        <div class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4">
                                                            Total
                                                        </div>
                                                        <div
                                                            id="lblVlrLimiteTotal"
                                                            class="row text-subtitle2 justify-center"
                                                        >
                                                            {{ limiteTotal }}
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4">
                                                            Utilizado
                                                        </div>
                                                        <div
                                                            id="lblVlrLimiteUtilizado"
                                                            class="row text-subtitle2 justify-center"
                                                        >
                                                            {{ limiteUtilizado }}
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4">
                                                            Disponível *
                                                        </div>
                                                        <div
                                                            id="lblVlrLimiteDisponivel"
                                                            class="row text-subtitle2 justify-center"
                                                        >
                                                            {{ limiteDisponivel }}
                                                        </div>
                                                        <div class="row justify-center">
                                                            <p class="text-caption text-center text-grey-6 q-ma-none">
                                                                * Limite sujeito a análise
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </q-card-section>
                                            <q-separator v-if="!mostrarMensagemLimite" />
                                            <q-card-section>
                                                <template v-if="mostrarMensagemLimite">
                                                    <div class="row justify-evenly">
                                                        <span style="width: 300px;" />
                                                    </div>
                                                    <q-banner-mensagem
                                                        v-if="false"
                                                        :mensagem="mensagemLimite"
                                                    />
                                                </template>
                                            </q-card-section>
                                        </q-card>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </q-page>
</template>

<script>
import DependenteService from '@/services/dependente'
import ClienteService from '@/services/cliente'
import CartaoService from '@/services/cartao'

import QClienteSemCartao from '@/components/Cartao/clienteSemCartao'
import QBannerMensagem from '@/components/shared/QBannerMensagem'
import QCartaoDigital from '@/components/shared/QCartaoDigital'

import MixinMessages from '@/mixins/mixinMessages'

import login from '@/plugins/login'

import { date } from 'quasar'

export default {
    name: 'PgCartao',

    components: {
        QClienteSemCartao,
        QBannerMensagem,
        QCartaoDigital
    },

    mixins: [
        MixinMessages
    ],

    data() {
        return {
            loadingClientes: true,

            loadingCartao: true,

            loadingLimite: true,

            clienteSemCartao: false,

            dependenteSemCartao: false,

            clienteSelecionado: null,

            cartao: {},

            limite: {},

            clienteOptions: {},
        }
    },

    watch: {
        clienteSelecionado: function () {
            this.$_clienteSelecionado()
        }
    },

    computed: {
        dataCadastro() {
            return this.cartao[0] ? date.formatDate(this.cartao[0].data_cadastro, 'MM/YYYY') : null
        },

        idCartao() {
            return this.cartao[0] ? this.cartao[0].id_cartao : null
        },

        nomeCliente() {
            return this.cartao[0] ? this.cartao[0].nome_abreviado : null
        },

        numeroCartaoMascara() {
            return this.cartao[0] ? this.cartao[0].mascara_cartao : null
        },

        numeroCartao() {
            return this.cartao[0] ? this.cartao[0].numero_cartao : null
        },

        limiteTotal() {
            return `R$ ${String(Number(this.limite.limite || 0).toFixed(2)).replace('.', ',')}`
        },

        limiteDisponivel() {
            return `R$ ${String(Number(this.limite.disponivel || 0).toFixed(2)).replace('.', ',')}`
        },

        limiteUtilizado() {
            return `R$ ${String(Number(this.limite.utilizado || 0).toFixed(2)).replace('.', ',')}`
        },

        mensagemLimite() {
            return this.limite.mensagem
        },

        mostrarMensagemLimite() {
            return this.limite.mensagem !== null
        },

        rede() {
            return process.env.VUE_APP_DESCRICAO_EMPRESA
        },

        empresa() {
            return process.env.VUE_APP_EMPRESA
        },

        mostrarClienteSemCartao() {
            return this.clienteSemCartao
        },

        mostrarDependenteSemCartao() {
            return this.dependenteSemCartao
        },

        mostrarCartao() {
            return !this.clienteSemCartao && !this.dependenteSemCartao
        },

        usuario() {
            return login.usuarioLogado()
        },
    },

    mounted() {
        this.$_getDadosCliente(this.$route.query.primeiro_acesso)
    },

    methods: {
        $_getDadosCliente(primeiroAcesso) {
            this.loadingClientes = true
            if (!this.usuario.id_dependente) {
                DependenteService.get({ id_cliente: this.usuario.id })
                    .then(({ data }) => {
                        this.clienteOptions = [{
                            label: this.usuario.nome,
                            type: 0,
                            id_cliente: this.usuario.id,
                            id_dependente: null
                        }]
                        if (primeiroAcesso) {
                            this.clienteSelecionado = this.clienteOptions[0]
                        }
                        if (data.length > 0) {
                            this.clienteOptions = [
                                ...this.clienteOptions,
                                ...data.map(el => ({
                                    label: el.nome,
                                    type: 1,
                                    id_cliente: this.usuario.id,
                                    id_dependente: el.id
                                }))
                            ]
                        }
                        this.loadingClientes = false
                    })
                    .catch(e => this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao buscar os dados do cliente',
                        error: e
                    })
                    )
            } else {
                DependenteService.getDependente(this.usuario.id_dependente)
                    .then(({ data }) => {
                        this.clienteOptions = [
                            {
                                label: data.nome,
                                type: 2,
                                id_cliente: this.usuario.id,
                                id_dependente: data.id
                            }
                        ]
                        this.loadingClientes = false
                    })
                    .catch(e => this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao buscar os dados do dependente',
                        error: e
                    })
                    )
            }
        },

        $_clienteSelecionado() {
            this.clienteSemCartao = false
            this.dependenteSemCartao = false
            this.$_getCartao({
                id_cliente: this.clienteSelecionado.id_cliente,
                id_dependente: this.clienteSelecionado.id_dependente,
                type: this.clienteSelecionado.type
            })
        },

        $_getCartao(value) {
            this.loadingCartao = true
            this.loadingLimite = true
            CartaoService.get({
                id_cliente: value.id_cliente,
                id_dependente: value.id_dependente,
                ativo: true,
                expand: 'cliente,dependente'
            })
                .then(({ data }) => {
                    if (data[0]){
                        if (value.type === 0) {
                            this.cartao = [
                                ...data.filter(el => !el.id_dependente).map(el => ({
                                    id_cartao: el.id,
                                    id_cliente: el.id_cliente,
                                    numero_cartao: el.numero_cartao,
                                    mascara_cartao: el.mascara_cartao,
                                    nome_abreviado: el.nome_abreviado,
                                    data_cadastro: el.cliente.data_cadastro
                                }))
                            ]
                            this.$_getLimiteCliente(this.cartao[0].id_cliente)
                            this.loadingCartao = false
                        } else {
                            this.cartao = [
                                ...data.filter(el => el.id_dependente).map(el => ({
                                    id_cartao: el.id,
                                    id_cliente: el.id_dependente,
                                    numero_cartao: el.numero_cartao,
                                    mascara_cartao: el.mascara_cartao,
                                    nome_abreviado: el.nome_abreviado,
                                    data_cadastro: el.cliente.data_cadastro
                                }))
                            ]
                            this.$_getLimiteDependente(this.cartao[0].id_cliente)
                            this.loadingCartao = false
                        }
                    } else {
                        if (value.type === 0) {
                            this.clienteSemCartao = true
                        } else if (value.type === 1) {
                            this.dependenteSemCartao = true
                        }
                    }
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Ocorreu um erro ao buscar os dados do cartão.',
                    error: e
                })
                )
        },

        $_getLimiteCliente(id) {
            ClienteService.getLimite(id)
                .then(({ data }) => {
                    this.limite = data
                    this.loadingLimite = false
                })
                .catch(e => {
                    this.$q.loadingClientes.hide()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar o cartão do cliente',
                        error: e
                    })
                })
        },

        $_getLimiteDependente(id) {
            DependenteService.getLimite(id)
                .then(({ data }) => {
                    this.limite = data
                    this.loadingLimite = false
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Ocorreu um erro ao buscar o cartão do dependente',
                    error: e
                })
                )
        }
    }
}
</script>

<style>
</style>
